<template>
  <div class="h-screen w-full bg-white">
    <auth-logo-banner/>

    <div class="vx-row no-gutter w-full items-center justify-center pb-base bg-white">
      <div class="vx-col w-full sm:w-8/12 md:w-6/12 lg:w-4/12 py-base">
        <vx-card no-shadow card-border>
          <div slot="no-body">
            <div class="vx-row no-gutter justify-center items-center p-8">
              <div class="vx-col w-full">
                <div class="vx-card__title mb-base">
                  <h4 class="mb-4 text-center lg:text-left">
                    {{ $t('RecoverPasswordTitle') }}
                  </h4>
                  <p class="text-justify">{{ $t('RecoverPasswordMsg') }}</p>
                </div>

                <form @submit.prevent>
                  <vs-input
                    v-model="email"
                    type="email"
                    v-validate="'required|email'"
                    data-vv-validate-on="input"
                    :name="$tc('$General.Email')"
                    :label-placeholder="$tc('$General.Email')"
                    class="w-full"
                    icon-no-border
                    icon="icon icon-mail"
                    icon-pack="feather"
                    :danger="errors.has($tc('$General.Email'))"
                    :danger-text="errors.first($tc('$General.Email'))"
                    val-icon-danger="clear"/>

                  <div class="flex flex-wrap-reverse justify-between items-center mt-base">
                    <vs-button
                      type="border"
                      class="w-full lg:w-auto"
                      @click.prevent="$router.back()">
                      {{ backToMsg }}
                    </vs-button>

                    <vs-button
                      class="w-full lg:w-auto mb-5 lg:mb-0"
                      @click.prevent="sendEmailToRecover()">
                      {{ $t('RecoverPasswordTitle') }}
                    </vs-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AuthLogoBanner from '@/views/pages/auth/components/AuthLogoBanner.vue';

/**
 * Page to request a recovery password email
 *
 * @module views/pages/auth/TheForgotPassword
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string} email - email address to send the recovery email
 * @vue-event {void} recoverPassword - send recover request. Mapped from
 * {@link module:store/auth/actions~recoverPassword}
 * @vue-event {void} sendEmailToRecover - make recover action and notify the user
 */
export default {
  name: 'TheForgotPassword',
  i18n: {
    messages: {
      en: {
        RecoverPasswordTitle: 'Recover your password',
        RecoverPasswordMsg: 'Please enter your email address and we\'ll send you instructions on how to reset your password.',
        RecoveryEmail: 'Recovery email',
        RecoveryEmailSent: 'If your account exists, you will receive a recovery email',
      },
    },
  },
  components: {
    AuthLogoBanner,
  },
  data() {
    return {
      email: '',
    };
  },
  computed: {
    ...mapGetters({
      isUserLoggedIn: 'auth/isUserLoggedIn',
    }),
    backToMsg() {
      return this.isUserLoggedIn ? this.$t('$Auth.BackToDashboard') : this.$t('$Auth.BackToLogin');
    },
  },
  created() {
    const uri = window.location.toString();
    if (uri.endsWith('.html')) {
      const cleanUri = uri.replace('.html', '');
      window.history.replaceState({}, document.title, cleanUri);
    }
  },
  methods: {
    ...mapActions({
      recoverPassword: 'auth/recoverPassword',
    }),
    async sendEmailToRecover() {
      const formIsValid = await this.$validator.validate();

      if (!formIsValid) {
        document.getElementsByName([this.$validator.errors.items[0].field])[0].focus();
        return;
      }

      this.$vs.loading({ type: 'radius' });
      await this.recoverPassword(this.email);
      this.$vs.loading.close();

      this.$showSuccessActionNotification({
        title: this.$t('RecoveryEmail'),
        text: this.$t('RecoveryEmailSent'),
      });

      this.email = '';
      this.$validator.reset();
    },
  },
};
</script>
